import { Flexbox, H1, Text } from '@sonika-se/ui-kit'
import React from 'react'
import Layout from '../components/Layout'

export const PageNotFound: React.FC = (props) => {
  return (
    <Layout>
      <Flexbox align="center" justify="center" style={{ height: '100%' }}>
        <H1>Sidan kunde inte hittas</H1>
      </Flexbox>
    </Layout>
  )
}

export default PageNotFound
